import {
  archivePassenger, clearPassengerReRegistrationGuard,
  findPassengerIdentifiersByPassengerProfileId,
  getArchivedOrUnarchivedPassenger, refreshMobileMoneyProfile,
  sendNewPassengerPassword,
  unarchivePassenger
} from '../../../../services/passengers-service'
import { addAlert } from '../../../../utilities/helpers'

let _cachedComponentData = false

export default {
  props: {
    signedInUser: Object,
    passengerId: String,
    hasUserType: Function,
    setPassenger: Function,
    countries: Array,
    deviceTypes: Array,
    forceRootViewRefresh: Function
  },
  data () {
    return {
      name: 'passengers-passenger',
      currentPassenger: null,
      currentPassengerOriginal: null,
      apiInProgress: false,
      currentPassengerError: null,
      unarchiveDialogLoaded: false,
      isLoadingUnarchivePassengerIdentifiers: false,
      unarchivePassengerIdentifiers: [],
      unarchiveDialogPassenger: null,
      selectedUnarchivePassengerIdentifier: null,
      alternateIdentifierTypes: [{ text: 'Bluetooth Low Energy', value: 'ble' }]
    }
  },
  mounted () {
    this.getPassenger().then((passenger) => {
      if (this.$route.query.archive === '1') {
        this.archivePassenger(passenger)
      } else if (this.$route.query.unarchive === '1') {
        this.loadUnarchiveDialog(passenger)
      }
    })
  },
  computed: {
    hasAdminPermissions () {
      return this.$props.hasUserType && this.$props.hasUserType('admin')
    }
  },
  methods: {
    async refreshMobileMoneyProfile () {
      if (confirm('Confirm updating passenger\'s mobile money profile')) {
        this.apiInProgress = true

        try {
          await refreshMobileMoneyProfile(this.currentPassenger.id)
          _cachedComponentData = null
          this.getPassenger()
        } catch (err) {
          addAlert({ message: err, type: 'error' })
        } finally {
          this.apiInProgress = false
        }
      }
    },
    refreshView () {
      this.$router.go()
    },
    isFormValid () {
      return this.$refs && this.$refs.form && this.$refs.form.validate()
    },
    isNonValidPassenger () {
      return Boolean(this.currentPassenger.dateArchived || !this.currentPassenger.passengerIdentifier || !this.currentPassenger.passengerIdentifier.phoneNumber)
    },
    getPassenger () {
      if (_cachedComponentData) {
        this.currentPassenger = _cachedComponentData
        return Promise.resolve(this.currentPassenger)
      } else {
        this.apiInProgress = true

        return getArchivedOrUnarchivedPassenger({ id: this.$props.passengerId }).then((passenger) => {
          this.apiInProgress = false
          this.currentPassenger = this.$props.setPassenger({ passenger, countries: this.$props.countries, deviceTypes: this.$props.deviceTypes })
          console.debug('this.currentPassenger', this.currentPassenger)
          return this.currentPassenger
        }).catch((err) => {
          this.apiInProgress = false
          addAlert({ message: err, type: 'error' })
        })
      }
    },
    sendNewPassword (passenger) {
      const promptResult = prompt(`Enter "reset" below to confirm sending this passenger a new temporary password\nWARNING this will override any existing password on the account and will expire in 24 hours.`)

      if (promptResult !== null) {
        if (promptResult !== 'reset') {
          return alert('Incorrect confirmation word, please try again')
        }

        this.apiInProgress = true

        sendNewPassengerPassword(passenger.id).then(() => {
          addAlert({ message: `Successfully sent passenger ${passenger.passengerIdentifier.phoneNumber} a new password via SMS, Password will expire in 24 hours if not changed by the user.`, type: 'success' })
          this.getPassenger()
        }).catch((err) => {
          addAlert({ message: err, type: 'error' })
        }).finally(() => {
          this.apiInProgress = false
        })
      }
    },
    clearReRegistrationGuard (passenger) {
      const promptResult = prompt(`Enter "clear" below to confirm allowing this phone number to be re-registered.\nWARNING all previous transactions and trip history will be lost and a new profile attached to this phone number.`)

      if (promptResult !== null) {
        if (promptResult !== 'clear') {
          return alert('Incorrect confirmation word, please try again')
        }

        clearPassengerReRegistrationGuard(passenger.passengerIdentifier.id).then(() => {
          addAlert({ message: `Successfully cleared re-registration lock for ${passenger.passengerIdentifier.phoneNumber}.`, type: 'success' })
          this.getPassenger()
        }).catch((err) => {
          addAlert({ message: err, type: 'error' })
        })
      }
    },
    update () {
    },
    loadParentView () {
      if (!this.isDataEdited() || confirm('Discard changes made?')) {
        this.$router.push({ name: 'passengers' }, () => (this.$props.forceRootViewRefresh()))
      }
    },
    isDataEdited () {
      return false // !_.isEqual(this.currentPassenger, this.currentPassengerOriginal)
    },
    archivePassenger (passenger) {
      if (this.$router.query && this.$router.query.archive) {
        this.$router.replace({ query: null })
      }

      if (this.hasAdminPermissions) {
        const code = passenger.passengerIdentifier.phoneNumber.substring(passenger.passengerIdentifier.phoneNumber.length - 4)
        const promptResult = prompt(`Enter ${code} below to confirm archiving this passenger\nWARNING if the account has no recovery contact and the passenger cannot remember their PIN/password they may need to create a new account)`)

        if (promptResult !== null) {
          if (promptResult !== code) {
            return alert('Incorrect code, please try again')
          }

          archivePassenger(passenger.id).then(() => {
            addAlert({ message: `Successfully archived passenger ${passenger.passengerIdentifier.phoneNumber}`, type: 'success' })
            this.getPassenger()
          }).catch((err) => {
            addAlert({ message: err, type: 'error' })
          })
        }
      }
    },
    undoArchivePassenger (passenger, passengerIdentifierId) {
      if (this.hasUserType('admin')) {
        this.isLoadingUnarchivePassengerIdentifiers = true
        unarchivePassenger({ passengerProfileId: passenger.id, passengerIdentifierId }).then(() => {
          addAlert({ message: `Successfully unarchived passenger`, type: 'success' })
          this.isLoadingUnarchivePassengerIdentifiers = false
          this.unarchiveDialogLoaded = false
          this.$router.replace({ query: null })
          this.getPassenger()
        }).catch((err) => {
          this.isLoadingUnarchivePassengerIdentifiers = false
          addAlert({ message: err, type: 'error', isModal: true })
        })
      }
    },
    registerAlternateIdentifier () {
      this.$router.push({ name: 'passengers-passenger-alternate-identifiers', query: { id: '0' } })
    },
    loadUnarchiveDialog (passenger) {
      if (this.$router.query && this.$router.query.unarchive) {
        this.$router.replace({ query: null })
      }

      if (this.hasUserType('admin')) {
        if (!passenger.passengerIdentifier || !passenger.passengerIdentifier.phoneNumber) {
          return alert('Passengers without phone numbers cannot be unarchived')
        }

        this.unarchiveDialogLoaded = true
        this.isLoadingUnarchivePassengerIdentifiers = true
        this.unarchivePassengerIdentifiers = []
        this.selectedUnarchivePassengerIdentifier = null
        this.unarchiveDialogPassenger = passenger

        findPassengerIdentifiersByPassengerProfileId(this.unarchiveDialogPassenger.id).then((passengerIdentifiers) => {
          this.isLoadingUnarchivePassengerIdentifiers = false
          this.unarchivePassengerIdentifiers = passengerIdentifiers.map((o) => ({ text: o.phoneNumber.split('-')[o.phoneNumber.split('-').length - 1], value: o.id }))
        }).catch((err) => {
          this.isLoadingUnarchivePassengerIdentifiers = false
          addAlert({ message: err, type: 'error', isModal: true })
        })
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.isDataEdited() || confirm('Discard changes made?')) {
      _cachedComponentData = null
      next()
    } else {
      _cachedComponentData = this.currentPassenger
      next(false)
    }
  }
}
